import { UsStateEnum } from 'enum/us-state'

import { OfferingModel } from '../offerings/types'
import { PostalCodeModel } from '../postal-codes/types'
import { NestRestApiQuery } from '../types'

export const enum MarketSlug {
  manhattan = 'manhattan',
  miami = 'miami',
  newYork = 'new-york',
  queens = 'queens',
  brooklyn = 'brooklyn',
  lasVegas = 'las-vegas',
  columbus = 'columbus',
  seattle = 'seattle',
  charlotte = 'charlotte',
  raleigh = 'raleigh',
  stPetersburg = 'st-petersburg',
  tampa = 'tampa',
  phoenix = 'phoenix',
  tampaStPetes = 'tampa–st.petes',
  washington = 'washington',
}

export interface MarketModel {
  uuid: string
  createAt: string
  updateAt: string
  name: string
  slug: MarketSlug
  postalCodes: PostalCodeModel[]
  defaultMaxPayout: number
  canEditMaxPayout: boolean
  defaultOffering?: OfferingModel
  isEnabled: boolean
  isDefault: boolean
  referenceIndexName: string | null
  state: {
    name: UsStateEnum
    displayName: string
  }
}

export interface MarketModelByZipCode extends MarketModel {
  postalCodeRadius: 0 | 25 | 50 // 0 it is zip code of market
}

export interface GetAllMarketModel {
  items: MarketModel[]
}

export interface FindAllQuery extends NestRestApiQuery<MarketModel> {
  postalCode?: string
}
